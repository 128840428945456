.header {
  background: #fff;
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 298px;
  height: 90px;
  z-index: 100;
  &__logo {
    max-width: 156px;
    height: 42px;
    position: absolute;
    top: 18px;
    right: 34px;
  }
  @media screen and (max-width: 768px) {
    background: #fff url(../img/ico_logo01.png) no-repeat;
    background-size: 24px 20px;
    background-position: right 10px center;
    width: 80px;
    height: 40px;
  }
}

.nav {
  position: fixed;
  left: -300px;
  top: 90px;
  transition: .5s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  &.open {
    left: 0;
  }
  @media screen and (max-width: 768px) {
    top: 48px;
  }
}

.nav_list.open {
  .nav_list__item span::before {
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    width: 0;
  }
  .nav_list__item:nth-child(2) span::before {
    transition-delay: .1s;
  }
  .nav_list__item:nth-child(3) span::before {
    transition-delay: .2s;
  }
  .nav_list__item:nth-child(4) span::before {
    transition-delay: .3s;
  }
  .nav_list__item:nth-child(5) span::before {
    transition-delay: .4s;
  }
}

.nav_list {
  &__item {
    font-size: 27px;
    white-space: nowrap;
    line-height: 1;
    &.is_width_fix {
      background: #fff;
      width: 240px;
    }
    a {
      background: #fff;
      padding: 12px 32px 6px;
      line-height: 1.4;
      &:hover {
        opacity: 1;
      }
      span {
        position: relative;
        &::before {
          background: #000;
          content: "";
          position: absolute;
          left: 0;
          top: -2px;
          width: 100%;
          height: 27px;
          transition: .5s;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    &__item {
      font-size: 18px;
      &.is_width_fix {
        width: 181px;
      }
      a {
        span {
          &::before {
            height: 18px;
          }
        }
      }
    }
  }
}

.language_switch {
  background: #fff;
  position: fixed;
  right: 162px;
  top: 0;
  width: 120px;
  height: 56px;
  z-index: 100;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    text-align: center;
    width: 100%;
    height: 100%;
    img {
      display: block;
      margin-right: 10px;
      width: 15px;
      height: auto;
    }
    p {
      padding-top: 3px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 70px;
    height: 40px;
    right: 112px;
    padding-top: 2px;
    a {
      font-size: 13px;
      line-height: 40px;
      img {
        margin-right: 7px;
        width: 12px;
      }
      p {
        padding-top: 1px;
      }
    }
  }
}

.volume_control {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  width: 198px;
  height: 56px;
  z-index: 100;
  .ico_music01 {
    width: 18px;
    height: 18px;
  }
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    &__item {
      font-size: 18px;
      line-height: 1.4;
      a {
        color: #B2B2B2;
        cursor: pointer;
        &.active {
          color: #000;
        }
      }
      & + & {
        border-left: solid 1px #000;
        padding-left: 14px;
        margin-left: 14px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    width: 129px;
    height: 40px;
    .ico_music01 {
      width: 14px;
      height: 14px;
    }
    &__list {
      &__item {
        font-size: 13px;
        padding-top: 2px;
      }
    }
  }
}

.nav_btn {
  background: transparent;
  border: none;
  transition: all .3s ease-in-out;
  cursor: pointer;
  width: 34px;
  height: 22px;
  top: 34px;
  left: 34px;
  pointer-events: visible;
  position: absolute;
  text-align: center;
  z-index: 9999;
  a {
    height: 100%;
    display: block;
  }
  &, span {
    display: inline-block;
    transition: all .3s ease-in-out;
  }
  span {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 2px;
    background: #000;
    transform-origin: left top;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 0;
      bottom: 0;
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
  &.open {
    span {
      &:nth-of-type(1) {
        transform: rotate(45deg);
        top: -2px;
      }
      &:nth-of-type(2) {
        opacity: 0;
        right: 0;
      }
      &:nth-of-type(3) {
        transform: rotate(-45deg);
        bottom: -2px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    top: 13px;
    left: 9px;
    width: 21px;
    height: 16px;
    &.open {
      span {
        &:nth-of-type(1) {
          top: 0;
          left: 1px;
        }
        &:nth-of-type(3) {
          bottom: -1px;
        }
      }
    }
  }
}

.footer {
  background: #fff;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 110px 140px;
  z-index: 30;
  &__wrap {
    display: none;
  }
  &__left {
    max-width: 587px;
    width: 100%;
  }
  &__logo {
    max-width: 500px;
    width: 100%;
    height: auto;
  }
  &__txtList {
    margin-top: 120px;
    &__item {
      display: flex;
      font-size: 14px;
      line-height: 2.78;
      .is_left {
        text-align: right;
        width: 100px;
      }
      .is_right {
        font-weight: 500;
        margin-left: 30px;
      }
    }
  }
  &__right {
    margin-top: auto;
  }
  &__linkList {
    &__item {
      font-size: 14px;
      line-height: 2.78;
      &.is_ico {
        display: flex;
        margin-top: 12px;
        margin-bottom: 12px;
        a + a {
          margin-left: 14px;
        }
        img {
          width: 42px;
          height: auto;
        }
      }
      &.is_link {
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }
  @media screen and (max-width: 768px) {
    display: block;
    position: relative;
    padding: 60px 15px;
    z-index: 30;
    &__left {
      max-width: 587px;
      width: 100%;
    }
    &__logo {
      display: block;
      margin: auto;
      max-width: 295px;
      width: 78%;
    }
    &__txtList {
      margin: 60px auto 0;
      max-width: 280px;
      &__item {
        font-size: 11px;
        line-height: 1.54;
        margin-bottom: 16px;
        .is_left {
          width: 80px;
        }
        .is_right {
          margin-left: 16px;
          width: calc(100% - 80px);
          a {
            text-decoration: underline;
            & + a {
              margin-left: 16px;
            }
          }
        }
      }
    }
    &__right {
      margin-top: auto;
    }
    &__linkList {
      &__item {
        font-size: 14px;
        line-height: 2.78;
        &.is_ico {
          display: flex;
          justify-content: center;
          a + a {
            margin-left: 14px;
          }
          img {
            width: 42px;
            height: auto;
          }
        }
        a {
          text-decoration: underline;
        }
      }
    }
  }
}
