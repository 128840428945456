.pace {
  user-select: none;
  box-sizing: border-box;
  border-radius: 10px;
  background-clip: padding-box;
  z-index: 2000;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 100px;
  width: 313px;
  height: 60px;
  @media screen and (max-width: 768px) {
    bottom: 60px;
    width: 160px;
    height: 130px;
  }
}
.pace .pace-progress {
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: 26px;
  transform: translate3d(0%, 0px, 0px)!important;
  width: 150px;
  bottom: 0;
  height: 40px;
  font-size: 16px;
  font-weight: normal;
  color: #000;
  line-height: 60px;
  letter-spacing: .05em;
  font-family: 'Brown-Italic', sans-serif;
  @media screen and (max-width: 768px) {
    bottom: auto;
    top: 15px;
    font-size: 13px;
    width: 110px;
    height: 20px;
  }
}
.pace .pace-progress:after {
  content: 'Loading...' attr(data-progress-text);
  display: inline-block;
  width: 45px;
  text-align: right;
  padding-right: 16px;
}
.pace .pace-text {
  font-size: 22px;
  font-weight: normal;
  font-family: 'Brown-Italic', sans-serif;
  letter-spacing: .05em;
  line-height: 1.4;
  position: relative;
  &::before {
    content: "";
    background: #000;
    width: 203px;
    height: 1px;
    position: absolute;
    bottom: -5px;
    left: -18px;
  }
  @media screen and (max-width: 768px) {
    font-size: 17px;
    text-align: center;
    &::before {
      width: 100%;
      position: absolute;
      bottom: -5px;
      left: 0;
    }
  }
}
.pace .pace-btn {
  background: #000 url(../img/ico_arrow02.png) no-repeat;
  background-size: 11px 18px;
  background-position: left 13px center;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 22px;
  font-weight: normal;
  font-family: 'Brown-Italic', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  padding-left: 20px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 90px;
  height: 35px;
  &:hover {
    opacity: .7;
  }
  &.flash {
    animation: Flash1 1s infinite;
  }
  @media screen and (max-width: 768px) {
    background-size: 8px 13px;
    background-position: left 10px center;
    font-size: 15px;
    left: 0;
    top: auto;
    width: 64px;
    height: 24px;
  }
}

@keyframes Flash1 {
  50% {
    opacity: 0;
  }
}
