// z-index
// header volume_control 100
// bg_video 5
// fixed_logo 20
// section共通 10
// section5 underbox 10 overbox 30
// section6 以降 40
// footer 30

.loading {
  background: url(../img/bg_loading01.png) no-repeat;
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  &__logo {
    position: fixed;
    left: 20px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 725px;
    height: 138px;
  }
  .loading_logo01 {
    position: absolute;
    width: 165px;
    height: 139px;
    left: 0;
  }
  .loading_logo02 {
    position: relative;
    width: 317px;
    height: 138px;
  }
  @media screen and (max-width: 768px) {
    &__logo {
      left: 0;
      width: 275px;
      height: 74px;
    }
    .loading_logo01 {
      width: 87px;
      height: 73px;
    }
    .loading_logo02 {
      margin-left: auto;
      width: 168px;
      height: 73px;
    }
  }
}

.article {
  display: none;
  overflow: hidden;
  width: 100%;
  .bg_video {
    position: fixed;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    z-index: 5;
  }
  @media screen and (max-width: 768px) {
    .bg_video {
      width: auto;
      height: calc(var(--vh, 1vh) * 100);
    }
  }
}

.fixed_logo {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 725px;
  height: 138px;
  z-index: 20;
  .logo02 {
    position: relative;
    width: 317px;
    height: 138px;
  }
  .logo03 {
    position: absolute;
    width: 165px;
    height: 139px;
    left: 0;
  }
  @media screen and (max-width: 768px) {
    width: 275px;
    height: 74px;
    .logo02 {
      margin-left: auto;
      width: 168px;
      height: 73px;
    }
    .logo03 {
      width: 87px;
      height: 73px;
    }
  }
}

.fixed_arrow {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 21.2%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 40px;
  height: 29px;
  z-index: 20;
  .ico_arrow01 {
    position: relative;
  }
  @media screen and (max-width: 768px) {
    width: 22px;
    height: 16px;
    bottom: 41px;
  }
}

.slider_sec {
  display: flex!important;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 10;
  &__wrap {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}

.sec {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  z-index: 10;
  &__wrap {
    z-index: 10;
  }
}

.sec01 {
  &__box {
    position: relative;
    overflow: hidden;
    width: 73%;
  }
  @media screen and (max-width: 768px) {
    &__box {
      width: 78.4%;
    }
  }
}

.sec02 {
  &__box {
    position: relative;
    overflow: hidden;
    margin-right: 2%;
    width: 96%;
    height: 71.6vh;
    max-height: 744px;
    .img_sec02_01 {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 60%;
    }
    .img_sec02_02 {
      position: absolute;
      right: 0;
      top: 0;
      width: 44%;
    }
  }
  @media screen and (max-width: 768px) {
    &__box {
      margin-right: 0;
      width: 100%;
      height: 68vh;
      max-height: 456px;
      .img_sec02_01 {
        top: 0;
        bottom: auto;
        width: 94%;
      }
      .img_sec02_02 {
        top: auto;
        bottom: 0;
        width: 86%;
      }
    }
  }
}

.sec03 {
  &__box {
    overflow: hidden;
    position: relative;
    width: 57.8%;
  }
  @media screen and (max-width: 768px) {
    &__box {
      width: 100%;
    }
  }
}

.sec04 {
  &__box {
    overflow: hidden;
    position: relative;
    margin-top: auto;
    margin-bottom: 2%;
    margin-left: auto;
    width: 59%;
  }
  @media screen and (max-width: 768px) {
    &__box {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
      width: 100%;
    }
  }
}

.sec05 {
  width: 100%!important;
  z-index: 30;
  &__underbox {
    background: url(../img/bg_sec05.png) no-repeat;
    background-size: 72.9% auto;
    background-position: center center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
  }
  &__overbox {
    background: rgba(#fff, .9) url(../img/img_sec05.png) no-repeat;
    background-size: 81% auto;
    background-position: center center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 30;
    &.is_eng {
      background-image: url(../img/eng/img_sec05.png);
    }
  }
  @media screen and (max-width: 768px) {
    &__underbox {
      background: url(../img/bg_sec05_sp.png) no-repeat;
      background-size: 78.4% 79%;
      background-position: center center;
      width: 100%;
      height: 100%;
    }
    &__overbox {
      background: rgba(#fff, .9) url(../img/img_sec05_sp.png) no-repeat;
      background-size: 90% auto;
      background-position: center center;
      width: 100%;
      height: 100%;
      z-index: 30;
      &.is_eng {
        background-image: url(../img/eng/img_sec05_sp.png);
      }
    }
  }
}

.bg_sec06_and_contact {
  background: url(../img/bg_sec06.png) repeat-y;
  background-size: 100% auto;
  background-attachment: fixed;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  @media screen and (max-width: 768px) {
    background: url(../img/bg_sec06_sp.png) repeat-y;
    background-size: 100% auto;
    background-attachment: fixed;
  }
}

.sec06 {
  display: block;
  min-height: 100vh;
  height: auto;
  position: relative;
  z-index: 40;
  &__leftTxt01 {
    position: absolute;
    top: 100px;
    left: -15px;
    max-width: 133px;
    width: auto;
    height: calc(100vh - 110px);
    z-index: 20;
    img {
      width: auto;
      height: 100%;
    }
    &.is_eng {
      left: 15px;
    }
  }
  &__leftTxt02 {
    position: absolute;
    top: 100px;
    left: 80px;
    width: 55px;
    height: 340px;
    z-index: 20;
  }
  &__centerBox {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
  }
  &__rightTxt {
    position: absolute;
    top: 100px;
    right: -40px;
    max-width: 133px;
    width: auto;
    height: calc(100vh - 100px);
    img {
      width: auto;
      height: 100%;
    }
  }
  &__phase01 {
    position: relative;
    &__box {
      display: block;
      overflow: hidden;
      position: relative;
      margin-left: auto;
      margin-right: 2%;
      width: 89%;
    }
    &__txt01 {
      background: url(../img/bg_sec06_txt01.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      font-size: 14px;
      padding-left: 10%;
      line-height: 2.69;
      margin-left: 13%;
      margin-bottom: -45px;
      max-width: 1210px;
      position: relative;
      width: 100%;
      height: 323px;
      z-index: 10;
      &.is_eng {
        font-size: 16px;
        align-items: flex-start;
        justify-content: center;
        line-height: 2.5;
        flex-direction: column;
      }
    }
  }
  &__phase02 {
    position: relative;
    min-height: 200vh;
    .img_sec06_02 {
      position: absolute;
      bottom: 20px;
      left: 20px;
      max-width: 411px;
      width: 21.4%;
      height: auto;
    }
    .img_sec06_03 {
      position: absolute;
      top: 76px;
      right: 0;
      max-width: 570px;
      width: 29.6%;
      height: auto;
    }
    .img_sec06_04 {
      position: absolute;
      bottom: 0;
      right: 15%;
      max-width: 539px;
      overflow: hidden;
      width: 28%;
      height: auto;
    }
    .img_sec06_05 {
      position: absolute;
      top: 76px;
      right: 30px;
      max-width: 520px;
      overflow: hidden;
      width: 27%;
      height: auto;
    }
    &__txt01 {
      background: url(../img/bg_sec06_txt02.png) no-repeat;
      background-size: 1000px 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      font-weight: 500;
      font-size: 14px;
      line-height: 2.69;
      padding-bottom: 4%;
      padding-left: 9%;
      margin-left: 13%;
      max-width: 1479px;
      position: relative;
      width: 80%;
      max-height: 851px;
      height: 100%;
      z-index: 50;
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 2.69;
      }
      span {
        font-weight: 900;
      }
      em {
        font-style: normal;
      }
      &.is_eng {
        background-image: url(../img/eng/bg_sec06_txt02.png);
        background-size: 1150px 90%;
        background-position: left center;
        font-weight: 600;
        padding-bottom: 7%;
        span {
          font-weight: bold;
        }
      }
    }
    &__txt02 {
      background: url(../img/bg_sec06_txt03.png) no-repeat;
      background-size: 100% 100%;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      font-size: 14px;
      padding-left: 9%;
      line-height: 2.69;
      margin-left: 13%;
      max-width: 1290px;
      position: relative;
      width: 67%;
      max-height: 570px;
      height: 100%;
      z-index: 50;
      span {
        font-weight: 900;
      }
      &.is_eng {
        background-image: url(../img/eng/bg_sec06_txt03.png);
        background-size: 1150px 100%;
        font-weight: 600;
        width: 75%;
        span {
          font-weight: bold;
        }
      }
    }
    &__txt03 {
      background: url(../img/bg_sec06_txt04.svg) no-repeat;
      background-size: 100% 100%;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      font-size: 14px;
      padding-left: 9%;
      line-height: 2.69;
      margin-left: 13%;
      max-width: 790px;
      position: relative;
      width: 100%;
      max-height: 227px;
      height: 100%;
      z-index: 50;
      span {
        font-weight: 900;
      }
      &.is_eng {
        background-image: url(../img/eng/bg_sec06_txt04.png);
        font-weight: 600;
        span {
          font-weight: bold;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    &__leftTxt01 {
      top: 273px;
      left: 6px;
      max-width: 39px;
      width: 39px;
      height: auto;
      &.is_second {
        top: 306px;
      }
      &.is_eng {
        top: 74px;
        left: 6px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    &__leftTxt02 {
      top: 74px;
      left: 15px;
      width: 30px;
      height: auto;
      &.is_eng {
        display: none;
      }
    }
    &__centerBox {
      justify-content: center;
    }
    &__rightTxt {
      top: auto;
      bottom: -50px;
      right: -5px;
      max-width: 39px;
      width: 39px;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
    &__phase01 {
      .img_sec06_01 {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
      &__txt01 {
        background: url(../img/bg_sec06_txt01_sp.png) no-repeat;
        background-size: 100% 100%;
        font-size: 11px;
        padding-left: 10%;
        padding-bottom: 50px;
        line-height: 3.63;
        margin-left: auto;
        margin-bottom: 12px;
        max-width: 84%;
        width: 100%;
        height: 269px;
        &.is_eng {
          background-image: url(../img/eng/bg_sec06_txt01_sp.png);
          font-size: 11px;
          align-items: flex-start;
          justify-content: center;
          line-height: 2.2;
          flex-direction: column;
          padding-bottom: 0;
          span {
            margin-bottom: 15px;
          }
        }
      }
    }
    &__phase02 {
      .img_sec06_02 {
        bottom: 10px;
        left: 10px;
        max-width: 121px;
        width: 32.2%;
        height: auto;
      }
      .img_sec06_03 {
        top: 30px;
        right: 10px;
        max-width: 154px;
        width: 41%;
        height: auto;
      }
      .img_sec06_04 {
        bottom: 0;
        left: 5%;
        width: 58%;
        height: auto;
      }
      .img_sec06_05 {
        position: absolute;
        top: 100px;
        right: 10px;
        max-width: 520px;
        width: 41%;
        height: auto;
        &.is_eng {
          top: 45px;
        }
      }
      &__txt01 {
        background: url(../img/bg_sec06_txt02_sp.png) no-repeat;
        background-size: 100% 100%;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 4%;
        padding-right: 12%;
        padding-left: 9%;
        margin-left: auto;
        max-width: 84%;
        width: 100%;
        max-height: 520px;
        height: 100%;
        z-index: 50;
        p {
          font-size: 10px;
          font-weight: 500;
          line-height: 1.9;
        }
        span {
          font-size: 11px;
          font-weight: 900;
        }
        &.is_eng {
          background-image: url(../img/eng/bg_sec06_txt02_sp.png);
          background-size: 100% 100%;
          font-size: 11px;
          line-height: 1.54;
          padding-top: 10%;
          padding-right: 15px;
          max-height: 543px;
          span {
            margin-bottom: 5px;
          }
        }
      }
      &__txt02 {
        background: url(../img/bg_sec06_txt03_sp.png) no-repeat;
        background-size: 100% 100%;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        font-size: 10px;
        font-weight: 500;
        padding-top: 130px;
        padding-left: 9%;
        padding-right: 9%;
        line-height: 1.9;
        margin-left: auto;
        max-width: 84%;
        width: 100%;
        max-height: 620px;
        height: 100%;
        span {
          font-size: 11px;
          font-weight: 900;
        }
        &.is_eng {
          background-image: url(../img/eng/bg_sec06_txt03_sp.png);
          background-size: 100% 100%;
          line-height: 1.7;
          padding-right: 5px;
          padding-top: 30px;
          width: 86.6%;
          span {
            margin-bottom: 5px;
          }
        }
        &__list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          &__item {
            font-size: 11px;
            &:nth-child(2n) {
              width: 40%;
            }
            &:nth-child(2n) {
              width: 60%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 320px) {
    &__phase02 {
      &__txt01 {
        p {
          line-height: 1.5;
        }
      }
      &__txt02 {
        line-height: 1.5;
      }
    }
  }
}

.sec_contact {
  z-index: 30;
  &__leftTxt01 {
    position: absolute;
    top: 100px;
    left: -15px;
    max-width: 133px;
    width: auto;
    height: calc(100vh - 110px);
    z-index: 20;
    img {
      width: auto;
      height: 100%;
    }
  }
  &__rightTxt {
    position: absolute;
    top: 100px;
    right: -40px;
    max-width: 133px;
    width: auto;
    height: calc(100vh - 100px);
    img {
      width: auto;
      height: 100%;
    }
  }
  &__box {
    background: #fff;
    display: block;
    max-width: 1080px;
    padding: 120px 0;
    position: relative;
    width: 100%;
    z-index: 20;
    &__message {
      position: absolute;
      top: 60px;
      left: 140px;
    }
    &__item {
      margin-bottom: 16px;
      &.is_confirm {
        input, select, textarea {
          border: none;
          &:focus {
            outline: 0;
          }
        }
      }
    }
    label {
      box-sizing: border-box;
      font-size: 16px;
      display: inline-block;
      text-align: right;
      margin-right: 20px;
      padding-top: 8px;
      vertical-align: top;
      width: 130px;
    }
    input, select, textarea {
      appearance: none;
      box-sizing: border-box;
      border: solid 2px #F2F2F2;
      font-size: 16px;
      height: 40px;
      padding: 0 14px;
      max-width: 820px;
      vertical-align: middle;
      width: 100%;
    }
    input {
      &::placeholder {
        position: relative;
        top: 2px;
      }
    }
    select {
      border-radius: 0;
      margin-left: -4px;
      padding-top: 2px;
    }
    textarea {
      height: 236px;
      padding: 14px;
    }
    button {
      appearance: none;
      cursor: pointer;
      box-sizing: border-box;
      border: solid 2px #F2F2F2;
      font-size: 16px;
      font-weight: 900;
      line-height: 50px;
      height: 50px;
      padding: 0 14px;
      max-width: 820px;
      vertical-align: middle;
      width: 100%;
    }
    #backBtn, button[type=submit] {
      max-width: 390px;
    }
    button[type=submit] {
      background: #000;
      color: #fff;
      margin-left: 40px;
    }
    &__text {
      font-size: 16px;
      text-align: center;
      span {
        font-weight: normal;
      }
    }
  }
  @media screen and (max-width: 768px) {
    &__leftTxt01 {
      top: 74px;
      left: 6px;
      max-width: 39px;
      width: 39px;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
    &__rightTxt {
      display: none;
    }
    &__box {
      margin-left: auto;
      margin-right: 10px;
      max-width: 81%;
      padding: 20px;
      &__message {
        position: absolute;
        top: 10px;
        left: auto;
        right: 20px;
      }
      &__item {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
        &.is_confirm {
          input, select, textarea {
            border: none;
            &:focus {
              outline: 0;
            }
          }
        }
      }
      label {
        font-size: 12px;
        display: block;
        text-align: left;
        margin-right: 0;
        width: auto;
      }
      input, select, textarea {
        height: 36px;
      }
      select {
        border-radius: 0;
        margin-left: -4px;
      }
      textarea {
        height: 136px;
        padding: 14px;
      }
      #backBtn, button[type=submit] {
        max-width: 390px;
      }
      button[type=submit] {
        background: #000;
        color: #fff;
        margin-left: 40px;
      }
      &__text {
        font-size: 16px;
        text-align: center;
        span {
          font-weight: normal;
        }
      }
    }
  }
}
