@charset "UTF-8";
@import "~normalize.css/normalize.css";
@import '~slick-carousel/slick/slick.css';
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap");
/*============================================================================================================
	base
============================================================================================================*/
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
body {
  color: #000;
  -webkit-text-size-adjust: 100%;
  font-weight: 900;
  line-height: 1.8;
  letter-spacing: normal;
}

div, h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt, dd, th, td, section, article, header, footer, main, nav, span, a {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.5;
  font-weight: normal;
  margin: 0;
  word-wrap: break-word;
}

p, li, td, th, dt, dd {
  font-size: 18px;
  margin: 0;
  word-wrap: break-word;
}
@media screen and (max-width: 768px) {
  p, li, td, th, dt, dd {
    font-size: 14px;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: top;
}

ul, ol, dl {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

.cf:after {
  content: "";
  display: block;
  clear: both;
}

@media screen and (min-width: 769px) {
  .sp {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  .pc {
    display: none !important;
  }
}
/*------------------------------------------
	font
------------------------------------------*/
@font-face {
  font-family: "Brown";
  src: url(../font/Brown-Bold.ttf);
}
@font-face {
  font-family: "Brown-Italic";
  src: url(../font/Brown-RegularItalic.ttf);
}
body, .f-gothic {
  font-family: "Brown", "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, sans-serif;
}

.f-serif {
  font-family: "游明朝体", YuMincho, "游明朝", "Yu Mincho", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 600;
}

.noto-sans {
  font-family: "Noto Sans JP", sans-serif;
}

.brown-italic {
  font-family: "Brown-Italic", sans-serif;
}

.source-sans {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
}

/*------------------------------------------
	frame
------------------------------------------*/
body {
  position: relative;
}
@media screen and (min-width: 769px) {
  body {
    min-width: 1000px;
  }
}

/*------------------------------------------
	hover
------------------------------------------*/
@media screen and (min-width: 769px) {
  a {
    transition: all 0.3s ease-in-out;
  }
  a:hover {
    opacity: 0.7;
  }
}
.header {
  background: #fff;
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 298px;
  height: 90px;
  z-index: 100;
}
.header__logo {
  max-width: 156px;
  height: 42px;
  position: absolute;
  top: 18px;
  right: 34px;
}
@media screen and (max-width: 768px) {
  .header {
    background: #fff url(../img/ico_logo01.png) no-repeat;
    background-size: 24px 20px;
    background-position: right 10px center;
    width: 80px;
    height: 40px;
  }
}

.nav {
  position: fixed;
  left: -300px;
  top: 90px;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}
.nav.open {
  left: 0;
}
@media screen and (max-width: 768px) {
  .nav {
    top: 48px;
  }
}

.nav_list.open .nav_list__item span::before {
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  width: 0;
}
.nav_list.open .nav_list__item:nth-child(2) span::before {
  transition-delay: 0.1s;
}
.nav_list.open .nav_list__item:nth-child(3) span::before {
  transition-delay: 0.2s;
}
.nav_list.open .nav_list__item:nth-child(4) span::before {
  transition-delay: 0.3s;
}
.nav_list.open .nav_list__item:nth-child(5) span::before {
  transition-delay: 0.4s;
}

.nav_list__item {
  font-size: 27px;
  white-space: nowrap;
  line-height: 1;
}
.nav_list__item.is_width_fix {
  background: #fff;
  width: 240px;
}
.nav_list__item a {
  background: #fff;
  padding: 12px 32px 6px;
  line-height: 1.4;
}
.nav_list__item a:hover {
  opacity: 1;
}
.nav_list__item a span {
  position: relative;
}
.nav_list__item a span::before {
  background: #000;
  content: "";
  position: absolute;
  left: 0;
  top: -2px;
  width: 100%;
  height: 27px;
  transition: 0.5s;
}
@media screen and (max-width: 768px) {
  .nav_list__item {
    font-size: 18px;
  }
  .nav_list__item.is_width_fix {
    width: 181px;
  }
  .nav_list__item a span::before {
    height: 18px;
  }
}

.language_switch {
  background: #fff;
  position: fixed;
  right: 162px;
  top: 0;
  width: 120px;
  height: 56px;
  z-index: 100;
}
.language_switch a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-align: center;
  width: 100%;
  height: 100%;
}
.language_switch a img {
  display: block;
  margin-right: 10px;
  width: 15px;
  height: auto;
}
.language_switch a p {
  padding-top: 3px;
}
@media screen and (max-width: 768px) {
  .language_switch {
    width: 70px;
    height: 40px;
    right: 112px;
    padding-top: 2px;
  }
  .language_switch a {
    font-size: 13px;
    line-height: 40px;
  }
  .language_switch a img {
    margin-right: 7px;
    width: 12px;
  }
  .language_switch a p {
    padding-top: 1px;
  }
}

.volume_control {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  width: 198px;
  height: 56px;
  z-index: 100;
}
.volume_control .ico_music01 {
  width: 18px;
  height: 18px;
}
.volume_control__list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}
.volume_control__list__item {
  font-size: 18px;
  line-height: 1.4;
}
.volume_control__list__item a {
  color: #B2B2B2;
  cursor: pointer;
}
.volume_control__list__item a.active {
  color: #000;
}
.volume_control__list__item + .volume_control__list__item {
  border-left: solid 1px #000;
  padding-left: 14px;
  margin-left: 14px;
}
@media screen and (max-width: 768px) {
  .volume_control {
    width: 129px;
    height: 40px;
  }
  .volume_control .ico_music01 {
    width: 14px;
    height: 14px;
  }
  .volume_control__list__item {
    font-size: 13px;
    padding-top: 2px;
  }
}

.nav_btn {
  background: transparent;
  border: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: 34px;
  height: 22px;
  top: 34px;
  left: 34px;
  pointer-events: visible;
  position: absolute;
  text-align: center;
  z-index: 9999;
}
.nav_btn a {
  height: 100%;
  display: block;
}
.nav_btn, .nav_btn span {
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
.nav_btn span {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 2px;
  background: #000;
  transform-origin: left top;
}
.nav_btn span:nth-of-type(1) {
  top: 0;
}
.nav_btn span:nth-of-type(2) {
  top: 0;
  bottom: 0;
}
.nav_btn span:nth-of-type(3) {
  bottom: 0;
}
.nav_btn.open span:nth-of-type(1) {
  transform: rotate(45deg);
  top: -2px;
}
.nav_btn.open span:nth-of-type(2) {
  opacity: 0;
  right: 0;
}
.nav_btn.open span:nth-of-type(3) {
  transform: rotate(-45deg);
  bottom: -2px;
}
@media screen and (max-width: 768px) {
  .nav_btn {
    top: 13px;
    left: 9px;
    width: 21px;
    height: 16px;
  }
  .nav_btn.open span:nth-of-type(1) {
    top: 0;
    left: 1px;
  }
  .nav_btn.open span:nth-of-type(3) {
    bottom: -1px;
  }
}

.footer {
  background: #fff;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 110px 140px;
  z-index: 30;
}
.footer__wrap {
  display: none;
}
.footer__left {
  max-width: 587px;
  width: 100%;
}
.footer__logo {
  max-width: 500px;
  width: 100%;
  height: auto;
}
.footer__txtList {
  margin-top: 120px;
}
.footer__txtList__item {
  display: flex;
  font-size: 14px;
  line-height: 2.78;
}
.footer__txtList__item .is_left {
  text-align: right;
  width: 100px;
}
.footer__txtList__item .is_right {
  font-weight: 500;
  margin-left: 30px;
}
.footer__right {
  margin-top: auto;
}
.footer__linkList__item {
  font-size: 14px;
  line-height: 2.78;
}
.footer__linkList__item.is_ico {
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;
}
.footer__linkList__item.is_ico a + a {
  margin-left: 14px;
}
.footer__linkList__item.is_ico img {
  width: 42px;
  height: auto;
}
.footer__linkList__item.is_link {
  font-weight: 500;
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .footer {
    display: block;
    position: relative;
    padding: 60px 15px;
    z-index: 30;
  }
  .footer__left {
    max-width: 587px;
    width: 100%;
  }
  .footer__logo {
    display: block;
    margin: auto;
    max-width: 295px;
    width: 78%;
  }
  .footer__txtList {
    margin: 60px auto 0;
    max-width: 280px;
  }
  .footer__txtList__item {
    font-size: 11px;
    line-height: 1.54;
    margin-bottom: 16px;
  }
  .footer__txtList__item .is_left {
    width: 80px;
  }
  .footer__txtList__item .is_right {
    margin-left: 16px;
    width: calc(100% - 80px);
  }
  .footer__txtList__item .is_right a {
    text-decoration: underline;
  }
  .footer__txtList__item .is_right a + a {
    margin-left: 16px;
  }
  .footer__right {
    margin-top: auto;
  }
  .footer__linkList__item {
    font-size: 14px;
    line-height: 2.78;
  }
  .footer__linkList__item.is_ico {
    display: flex;
    justify-content: center;
  }
  .footer__linkList__item.is_ico a + a {
    margin-left: 14px;
  }
  .footer__linkList__item.is_ico img {
    width: 42px;
    height: auto;
  }
  .footer__linkList__item a {
    text-decoration: underline;
  }
}

.loading {
  background: url(../img/bg_loading01.png) no-repeat;
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
}
.loading__logo {
  position: fixed;
  left: 20px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 725px;
  height: 138px;
}
.loading .loading_logo01 {
  position: absolute;
  width: 165px;
  height: 139px;
  left: 0;
}
.loading .loading_logo02 {
  position: relative;
  width: 317px;
  height: 138px;
}
@media screen and (max-width: 768px) {
  .loading__logo {
    left: 0;
    width: 275px;
    height: 74px;
  }
  .loading .loading_logo01 {
    width: 87px;
    height: 73px;
  }
  .loading .loading_logo02 {
    margin-left: auto;
    width: 168px;
    height: 73px;
  }
}

.article {
  display: none;
  overflow: hidden;
  width: 100%;
}
.article .bg_video {
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  z-index: 5;
}
@media screen and (max-width: 768px) {
  .article .bg_video {
    width: auto;
    height: calc(var(--vh, 1vh) * 100);
  }
}

.fixed_logo {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 725px;
  height: 138px;
  z-index: 20;
}
.fixed_logo .logo02 {
  position: relative;
  width: 317px;
  height: 138px;
}
.fixed_logo .logo03 {
  position: absolute;
  width: 165px;
  height: 139px;
  left: 0;
}
@media screen and (max-width: 768px) {
  .fixed_logo {
    width: 275px;
    height: 74px;
  }
  .fixed_logo .logo02 {
    margin-left: auto;
    width: 168px;
    height: 73px;
  }
  .fixed_logo .logo03 {
    width: 87px;
    height: 73px;
  }
}

.fixed_arrow {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 21.2%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 40px;
  height: 29px;
  z-index: 20;
}
.fixed_arrow .ico_arrow01 {
  position: relative;
}
@media screen and (max-width: 768px) {
  .fixed_arrow {
    width: 22px;
    height: 16px;
    bottom: 41px;
  }
}

.slider_sec {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 10;
}
.slider_sec__wrap {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}

.sec {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  z-index: 10;
}
.sec__wrap {
  z-index: 10;
}

.sec01__box {
  position: relative;
  overflow: hidden;
  width: 73%;
}
@media screen and (max-width: 768px) {
  .sec01__box {
    width: 78.4%;
  }
}

.sec02__box {
  position: relative;
  overflow: hidden;
  margin-right: 2%;
  width: 96%;
  height: 71.6vh;
  max-height: 744px;
}
.sec02__box .img_sec02_01 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60%;
}
.sec02__box .img_sec02_02 {
  position: absolute;
  right: 0;
  top: 0;
  width: 44%;
}
@media screen and (max-width: 768px) {
  .sec02__box {
    margin-right: 0;
    width: 100%;
    height: 68vh;
    max-height: 456px;
  }
  .sec02__box .img_sec02_01 {
    top: 0;
    bottom: auto;
    width: 94%;
  }
  .sec02__box .img_sec02_02 {
    top: auto;
    bottom: 0;
    width: 86%;
  }
}

.sec03__box {
  overflow: hidden;
  position: relative;
  width: 57.8%;
}
@media screen and (max-width: 768px) {
  .sec03__box {
    width: 100%;
  }
}

.sec04__box {
  overflow: hidden;
  position: relative;
  margin-top: auto;
  margin-bottom: 2%;
  margin-left: auto;
  width: 59%;
}
@media screen and (max-width: 768px) {
  .sec04__box {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    width: 100%;
  }
}

.sec05 {
  width: 100% !important;
  z-index: 30;
}
.sec05__underbox {
  background: url(../img/bg_sec05.png) no-repeat;
  background-size: 72.9% auto;
  background-position: center center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
}
.sec05__overbox {
  background: rgba(255, 255, 255, 0.9) url(../img/img_sec05.png) no-repeat;
  background-size: 81% auto;
  background-position: center center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 30;
}
.sec05__overbox.is_eng {
  background-image: url(../img/eng/img_sec05.png);
}
@media screen and (max-width: 768px) {
  .sec05__underbox {
    background: url(../img/bg_sec05_sp.png) no-repeat;
    background-size: 78.4% 79%;
    background-position: center center;
    width: 100%;
    height: 100%;
  }
  .sec05__overbox {
    background: rgba(255, 255, 255, 0.9) url(../img/img_sec05_sp.png) no-repeat;
    background-size: 90% auto;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: 30;
  }
  .sec05__overbox.is_eng {
    background-image: url(../img/eng/img_sec05_sp.png);
  }
}

.bg_sec06_and_contact {
  background: url(../img/bg_sec06.png) repeat-y;
  background-size: 100% auto;
  background-attachment: fixed;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
}
@media screen and (max-width: 768px) {
  .bg_sec06_and_contact {
    background: url(../img/bg_sec06_sp.png) repeat-y;
    background-size: 100% auto;
    background-attachment: fixed;
  }
}

.sec06 {
  display: block;
  min-height: 100vh;
  height: auto;
  position: relative;
  z-index: 40;
}
.sec06__leftTxt01 {
  position: absolute;
  top: 100px;
  left: -15px;
  max-width: 133px;
  width: auto;
  height: calc(100vh - 110px);
  z-index: 20;
}
.sec06__leftTxt01 img {
  width: auto;
  height: 100%;
}
.sec06__leftTxt01.is_eng {
  left: 15px;
}
.sec06__leftTxt02 {
  position: absolute;
  top: 100px;
  left: 80px;
  width: 55px;
  height: 340px;
  z-index: 20;
}
.sec06__centerBox {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}
.sec06__rightTxt {
  position: absolute;
  top: 100px;
  right: -40px;
  max-width: 133px;
  width: auto;
  height: calc(100vh - 100px);
}
.sec06__rightTxt img {
  width: auto;
  height: 100%;
}
.sec06__phase01 {
  position: relative;
}
.sec06__phase01__box {
  display: block;
  overflow: hidden;
  position: relative;
  margin-left: auto;
  margin-right: 2%;
  width: 89%;
}
.sec06__phase01__txt01 {
  background: url(../img/bg_sec06_txt01.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-left: 10%;
  line-height: 2.69;
  margin-left: 13%;
  margin-bottom: -45px;
  max-width: 1210px;
  position: relative;
  width: 100%;
  height: 323px;
  z-index: 10;
}
.sec06__phase01__txt01.is_eng {
  font-size: 16px;
  align-items: flex-start;
  justify-content: center;
  line-height: 2.5;
  flex-direction: column;
}
.sec06__phase02 {
  position: relative;
  min-height: 200vh;
}
.sec06__phase02 .img_sec06_02 {
  position: absolute;
  bottom: 20px;
  left: 20px;
  max-width: 411px;
  width: 21.4%;
  height: auto;
}
.sec06__phase02 .img_sec06_03 {
  position: absolute;
  top: 76px;
  right: 0;
  max-width: 570px;
  width: 29.6%;
  height: auto;
}
.sec06__phase02 .img_sec06_04 {
  position: absolute;
  bottom: 0;
  right: 15%;
  max-width: 539px;
  overflow: hidden;
  width: 28%;
  height: auto;
}
.sec06__phase02 .img_sec06_05 {
  position: absolute;
  top: 76px;
  right: 30px;
  max-width: 520px;
  overflow: hidden;
  width: 27%;
  height: auto;
}
.sec06__phase02__txt01 {
  background: url(../img/bg_sec06_txt02.png) no-repeat;
  background-size: 1000px 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: 500;
  font-size: 14px;
  line-height: 2.69;
  padding-bottom: 4%;
  padding-left: 9%;
  margin-left: 13%;
  max-width: 1479px;
  position: relative;
  width: 80%;
  max-height: 851px;
  height: 100%;
  z-index: 50;
}
.sec06__phase02__txt01 p {
  font-weight: 500;
  font-size: 14px;
  line-height: 2.69;
}
.sec06__phase02__txt01 span {
  font-weight: 900;
}
.sec06__phase02__txt01 em {
  font-style: normal;
}
.sec06__phase02__txt01.is_eng {
  background-image: url(../img/eng/bg_sec06_txt02.png);
  background-size: 1150px 90%;
  background-position: left center;
  font-weight: 600;
  padding-bottom: 7%;
}
.sec06__phase02__txt01.is_eng span {
  font-weight: bold;
}
.sec06__phase02__txt02 {
  background: url(../img/bg_sec06_txt03.png) no-repeat;
  background-size: 100% 100%;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  padding-left: 9%;
  line-height: 2.69;
  margin-left: 13%;
  max-width: 1290px;
  position: relative;
  width: 67%;
  max-height: 570px;
  height: 100%;
  z-index: 50;
}
.sec06__phase02__txt02 span {
  font-weight: 900;
}
.sec06__phase02__txt02.is_eng {
  background-image: url(../img/eng/bg_sec06_txt03.png);
  background-size: 1150px 100%;
  font-weight: 600;
  width: 75%;
}
.sec06__phase02__txt02.is_eng span {
  font-weight: bold;
}
.sec06__phase02__txt03 {
  background: url(../img/bg_sec06_txt04.svg) no-repeat;
  background-size: 100% 100%;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  padding-left: 9%;
  line-height: 2.69;
  margin-left: 13%;
  max-width: 790px;
  position: relative;
  width: 100%;
  max-height: 227px;
  height: 100%;
  z-index: 50;
}
.sec06__phase02__txt03 span {
  font-weight: 900;
}
.sec06__phase02__txt03.is_eng {
  background-image: url(../img/eng/bg_sec06_txt04.png);
  font-weight: 600;
}
.sec06__phase02__txt03.is_eng span {
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .sec06__leftTxt01 {
    top: 273px;
    left: 6px;
    max-width: 39px;
    width: 39px;
    height: auto;
  }
  .sec06__leftTxt01.is_second {
    top: 306px;
  }
  .sec06__leftTxt01.is_eng {
    top: 74px;
    left: 6px;
  }
  .sec06__leftTxt01 img {
    width: 100%;
    height: auto;
  }
  .sec06__leftTxt02 {
    top: 74px;
    left: 15px;
    width: 30px;
    height: auto;
  }
  .sec06__leftTxt02.is_eng {
    display: none;
  }
  .sec06__centerBox {
    justify-content: center;
  }
  .sec06__rightTxt {
    top: auto;
    bottom: -50px;
    right: -5px;
    max-width: 39px;
    width: 39px;
    height: auto;
  }
  .sec06__rightTxt img {
    width: 100%;
    height: auto;
  }
  .sec06__phase01 .img_sec06_01 {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .sec06__phase01__txt01 {
    background: url(../img/bg_sec06_txt01_sp.png) no-repeat;
    background-size: 100% 100%;
    font-size: 11px;
    padding-left: 10%;
    padding-bottom: 50px;
    line-height: 3.63;
    margin-left: auto;
    margin-bottom: 12px;
    max-width: 84%;
    width: 100%;
    height: 269px;
  }
  .sec06__phase01__txt01.is_eng {
    background-image: url(../img/eng/bg_sec06_txt01_sp.png);
    font-size: 11px;
    align-items: flex-start;
    justify-content: center;
    line-height: 2.2;
    flex-direction: column;
    padding-bottom: 0;
  }
  .sec06__phase01__txt01.is_eng span {
    margin-bottom: 15px;
  }
  .sec06__phase02 .img_sec06_02 {
    bottom: 10px;
    left: 10px;
    max-width: 121px;
    width: 32.2%;
    height: auto;
  }
  .sec06__phase02 .img_sec06_03 {
    top: 30px;
    right: 10px;
    max-width: 154px;
    width: 41%;
    height: auto;
  }
  .sec06__phase02 .img_sec06_04 {
    bottom: 0;
    left: 5%;
    width: 58%;
    height: auto;
  }
  .sec06__phase02 .img_sec06_05 {
    position: absolute;
    top: 100px;
    right: 10px;
    max-width: 520px;
    width: 41%;
    height: auto;
  }
  .sec06__phase02 .img_sec06_05.is_eng {
    top: 45px;
  }
  .sec06__phase02__txt01 {
    background: url(../img/bg_sec06_txt02_sp.png) no-repeat;
    background-size: 100% 100%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 4%;
    padding-right: 12%;
    padding-left: 9%;
    margin-left: auto;
    max-width: 84%;
    width: 100%;
    max-height: 520px;
    height: 100%;
    z-index: 50;
  }
  .sec06__phase02__txt01 p {
    font-size: 10px;
    font-weight: 500;
    line-height: 1.9;
  }
  .sec06__phase02__txt01 span {
    font-size: 11px;
    font-weight: 900;
  }
  .sec06__phase02__txt01.is_eng {
    background-image: url(../img/eng/bg_sec06_txt02_sp.png);
    background-size: 100% 100%;
    font-size: 11px;
    line-height: 1.54;
    padding-top: 10%;
    padding-right: 15px;
    max-height: 543px;
  }
  .sec06__phase02__txt01.is_eng span {
    margin-bottom: 5px;
  }
  .sec06__phase02__txt02 {
    background: url(../img/bg_sec06_txt03_sp.png) no-repeat;
    background-size: 100% 100%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    font-size: 10px;
    font-weight: 500;
    padding-top: 130px;
    padding-left: 9%;
    padding-right: 9%;
    line-height: 1.9;
    margin-left: auto;
    max-width: 84%;
    width: 100%;
    max-height: 620px;
    height: 100%;
  }
  .sec06__phase02__txt02 span {
    font-size: 11px;
    font-weight: 900;
  }
  .sec06__phase02__txt02.is_eng {
    background-image: url(../img/eng/bg_sec06_txt03_sp.png);
    background-size: 100% 100%;
    line-height: 1.7;
    padding-right: 5px;
    padding-top: 30px;
    width: 86.6%;
  }
  .sec06__phase02__txt02.is_eng span {
    margin-bottom: 5px;
  }
  .sec06__phase02__txt02__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .sec06__phase02__txt02__list__item {
    font-size: 11px;
  }
  .sec06__phase02__txt02__list__item:nth-child(2n) {
    width: 40%;
  }
  .sec06__phase02__txt02__list__item:nth-child(2n) {
    width: 60%;
  }
}
@media screen and (max-width: 320px) {
  .sec06__phase02__txt01 p {
    line-height: 1.5;
  }
  .sec06__phase02__txt02 {
    line-height: 1.5;
  }
}

.sec_contact {
  z-index: 30;
}
.sec_contact__leftTxt01 {
  position: absolute;
  top: 100px;
  left: -15px;
  max-width: 133px;
  width: auto;
  height: calc(100vh - 110px);
  z-index: 20;
}
.sec_contact__leftTxt01 img {
  width: auto;
  height: 100%;
}
.sec_contact__rightTxt {
  position: absolute;
  top: 100px;
  right: -40px;
  max-width: 133px;
  width: auto;
  height: calc(100vh - 100px);
}
.sec_contact__rightTxt img {
  width: auto;
  height: 100%;
}
.sec_contact__box {
  background: #fff;
  display: block;
  max-width: 1080px;
  padding: 120px 0;
  position: relative;
  width: 100%;
  z-index: 20;
}
.sec_contact__box__message {
  position: absolute;
  top: 60px;
  left: 140px;
}
.sec_contact__box__item {
  margin-bottom: 16px;
}
.sec_contact__box__item.is_confirm input, .sec_contact__box__item.is_confirm select, .sec_contact__box__item.is_confirm textarea {
  border: none;
}
.sec_contact__box__item.is_confirm input:focus, .sec_contact__box__item.is_confirm select:focus, .sec_contact__box__item.is_confirm textarea:focus {
  outline: 0;
}
.sec_contact__box label {
  box-sizing: border-box;
  font-size: 16px;
  display: inline-block;
  text-align: right;
  margin-right: 20px;
  padding-top: 8px;
  vertical-align: top;
  width: 130px;
}
.sec_contact__box input, .sec_contact__box select, .sec_contact__box textarea {
  appearance: none;
  box-sizing: border-box;
  border: solid 2px #F2F2F2;
  font-size: 16px;
  height: 40px;
  padding: 0 14px;
  max-width: 820px;
  vertical-align: middle;
  width: 100%;
}
.sec_contact__box input::placeholder {
  position: relative;
  top: 2px;
}
.sec_contact__box select {
  border-radius: 0;
  margin-left: -4px;
  padding-top: 2px;
}
.sec_contact__box textarea {
  height: 236px;
  padding: 14px;
}
.sec_contact__box button {
  appearance: none;
  cursor: pointer;
  box-sizing: border-box;
  border: solid 2px #F2F2F2;
  font-size: 16px;
  font-weight: 900;
  line-height: 50px;
  height: 50px;
  padding: 0 14px;
  max-width: 820px;
  vertical-align: middle;
  width: 100%;
}
.sec_contact__box #backBtn, .sec_contact__box button[type=submit] {
  max-width: 390px;
}
.sec_contact__box button[type=submit] {
  background: #000;
  color: #fff;
  margin-left: 40px;
}
.sec_contact__box__text {
  font-size: 16px;
  text-align: center;
}
.sec_contact__box__text span {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .sec_contact__leftTxt01 {
    top: 74px;
    left: 6px;
    max-width: 39px;
    width: 39px;
    height: auto;
  }
  .sec_contact__leftTxt01 img {
    width: 100%;
    height: auto;
  }
  .sec_contact__rightTxt {
    display: none;
  }
  .sec_contact__box {
    margin-left: auto;
    margin-right: 10px;
    max-width: 81%;
    padding: 20px;
  }
  .sec_contact__box__message {
    position: absolute;
    top: 10px;
    left: auto;
    right: 20px;
  }
  .sec_contact__box__item {
    margin-bottom: 16px;
  }
  .sec_contact__box__item:last-child {
    margin-bottom: 0;
  }
  .sec_contact__box__item.is_confirm input, .sec_contact__box__item.is_confirm select, .sec_contact__box__item.is_confirm textarea {
    border: none;
  }
  .sec_contact__box__item.is_confirm input:focus, .sec_contact__box__item.is_confirm select:focus, .sec_contact__box__item.is_confirm textarea:focus {
    outline: 0;
  }
  .sec_contact__box label {
    font-size: 12px;
    display: block;
    text-align: left;
    margin-right: 0;
    width: auto;
  }
  .sec_contact__box input, .sec_contact__box select, .sec_contact__box textarea {
    height: 36px;
  }
  .sec_contact__box select {
    border-radius: 0;
    margin-left: -4px;
  }
  .sec_contact__box textarea {
    height: 136px;
    padding: 14px;
  }
  .sec_contact__box #backBtn, .sec_contact__box button[type=submit] {
    max-width: 390px;
  }
  .sec_contact__box button[type=submit] {
    background: #000;
    color: #fff;
    margin-left: 40px;
  }
  .sec_contact__box__text {
    font-size: 16px;
    text-align: center;
  }
  .sec_contact__box__text span {
    font-weight: normal;
  }
}

.pace {
  user-select: none;
  box-sizing: border-box;
  border-radius: 10px;
  background-clip: padding-box;
  z-index: 2000;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 100px;
  width: 313px;
  height: 60px;
}
@media screen and (max-width: 768px) {
  .pace {
    bottom: 60px;
    width: 160px;
    height: 130px;
  }
}

.pace .pace-progress {
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: 26px;
  transform: translate3d(0%, 0px, 0px) !important;
  width: 150px;
  bottom: 0;
  height: 40px;
  font-size: 16px;
  font-weight: normal;
  color: #000;
  line-height: 60px;
  letter-spacing: 0.05em;
  font-family: "Brown-Italic", sans-serif;
}
@media screen and (max-width: 768px) {
  .pace .pace-progress {
    bottom: auto;
    top: 15px;
    font-size: 13px;
    width: 110px;
    height: 20px;
  }
}

.pace .pace-progress:after {
  content: "Loading..." attr(data-progress-text);
  display: inline-block;
  width: 45px;
  text-align: right;
  padding-right: 16px;
}

.pace .pace-text {
  font-size: 22px;
  font-weight: normal;
  font-family: "Brown-Italic", sans-serif;
  letter-spacing: 0.05em;
  line-height: 1.4;
  position: relative;
}
.pace .pace-text::before {
  content: "";
  background: #000;
  width: 203px;
  height: 1px;
  position: absolute;
  bottom: -5px;
  left: -18px;
}
@media screen and (max-width: 768px) {
  .pace .pace-text {
    font-size: 17px;
    text-align: center;
  }
  .pace .pace-text::before {
    width: 100%;
    position: absolute;
    bottom: -5px;
    left: 0;
  }
}

.pace .pace-btn {
  background: #000 url(../img/ico_arrow02.png) no-repeat;
  background-size: 11px 18px;
  background-position: left 13px center;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 22px;
  font-weight: normal;
  font-family: "Brown-Italic", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  padding-left: 20px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 90px;
  height: 35px;
}
.pace .pace-btn:hover {
  opacity: 0.7;
}
.pace .pace-btn.flash {
  animation: Flash1 1s infinite;
}
@media screen and (max-width: 768px) {
  .pace .pace-btn {
    background-size: 8px 13px;
    background-position: left 10px center;
    font-size: 15px;
    left: 0;
    top: auto;
    width: 64px;
    height: 24px;
  }
}

@keyframes Flash1 {
  50% {
    opacity: 0;
  }
}