$mq-breakpoints: (
  'pc': 'min-width: 769px',
  'sp': 'max-width: 768px',
);

@mixin mq($breakpoint: sp) {
  @media screen and (#{map-get($mq-breakpoints, $breakpoint)}) {
    @content;
  }
}

@function get_vw($size, $viewport: 375) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@function get_vw_pc($size, $viewport: 1920) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size:10){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}
