/*============================================================================================================
	base
============================================================================================================*/

body {
	color: #000;
		-webkit-text-size-adjust: 100%;
	font-weight: 900;
	line-height: 1.8;
	letter-spacing: normal;
}

div,h1,h2,h3,h4,h5,h6,p,ul,ol,li,dl,dt,dd,th,td,section,article,header,footer,main,nav,span,a {
	box-sizing: border-box;
}

a {
	color: inherit;
	text-decoration: none;
}

img {
	width: 100%;
	height: auto;
	vertical-align: bottom;
}

h1,h2,h3,h4,h5,h6 {
	line-height: 1.5;
	font-weight: normal;
	margin: 0;
	word-wrap: break-word;
}

p,li,td,th,dt,dd {
	font-size: 18px;
	margin: 0;
	word-wrap: break-word;
	@include mq {
		font-size: 14px;
	}
}

table {
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed;
}

th,td {
	text-align: left;
	font-weight: normal;
	vertical-align: top;
}

ul,ol,dl {
	padding: 0;
	margin: 0;
}

li {
	list-style: none;
}

.cf:after {
	content: "";
	display: block;
	clear: both;
}


@include mq(pc) {
	.sp {
		display: none !important;
	}
}

@include mq {
	.pc {
		display: none !important;
	}
}


/*------------------------------------------
	font
------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

@font-face {
  font-family: 'Brown';
  src: url(../font/Brown-Bold.ttf);
}

@font-face {
  font-family: 'Brown-Italic';
  src: url(../font/Brown-RegularItalic.ttf);
}

body, .f-gothic {
	font-family: 'Brown', 'Noto Sans JP', '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'ヒラギノ角ゴ ProN W3', 'メイリオ', Meiryo, sans-serif;
}

.f-serif {
	font-family: '游明朝体', YuMincho, '游明朝', 'Yu Mincho', 'Hiragino Mincho ProN', 'Hiragino Mincho Pro', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
	font-weight: 600;
}

.noto-sans {
	font-family: 'Noto Sans JP', sans-serif;
}

.brown-italic {
	font-family: 'Brown-Italic', sans-serif;
}

.source-sans {
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: bold;
}


/*------------------------------------------
	frame
------------------------------------------*/
body {
	position: relative;
	@include mq(pc) {
		min-width: 1000px;
	}
}


/*------------------------------------------
	hover
------------------------------------------*/
@include mq(pc) {
	a {
		transition: all .3s ease-in-out;
		&:hover {
			opacity: .7;
		}
	}
}

